@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=beecff6b-71a7-4c43-8eac-816e721e6173&fontids=1475496,1475520,1475532,1475544");
@font-face{
    font-family:"Avenir LT W01_35 Light1475496";
    src:url("../src/lib/assets/WebFonts/Fonts/1475496/0078f486-8e52-42c0-ad81-3c8d3d43f48e.woff2") format("woff2"),url("../src/lib/assets/WebFonts/Fonts/1475496/908c4810-64db-4b46-bb8e-823eb41f68c0.woff") format("woff");
}
@font-face{
    font-family:"Avenir LT W01_55 Roman1475520";
    src:url("../src/lib/assets/WebFonts/Fonts/1475520/b290e775-e0f9-4980-914b-a4c32a5e3e36.woff2") format("woff2"),url("../src/lib/assets/WebFonts/Fonts/1475520/4b978f72-bb48-46c3-909a-2a8cd2f8819c.woff") format("woff");
}
@font-face{
    font-family:"Avenir LT W01_65 Medium1475532";
    src:url("../src/lib/assets/WebFonts/Fonts/1475532/17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2") format("woff2"),url("../src/lib/assets/WebFonts/Fonts/1475532/c9aeeabd-dd65-491d-b4be-3e0db9ae47a0.woff") format("woff");
}
@font-face{
    font-family:"Avenir LT W01_85 Heavy1475544";
    src:url("../src/lib/assets/WebFonts/Fonts/1475544/d513e15e-8f35-4129-ad05-481815e52625.woff2") format("woff2"),url("../src/lib/assets/WebFonts/Fonts/1475544/61bd362e-7162-46bd-b67e-28f366c4afbe.woff") format("woff");
}
@font-face{
    font-family:"Slussen-Black";
    src:url("../src/lib/assets/WebFonts/Fonts/SlussenBlack/Slussen-Black.woff2") format("woff2"),url("../src/lib/assets/WebFonts/Fonts/SlussenBlack/Slussen-Black.woff") format("woff");
}
@font-face{
    font-family:"Slussen-Bold";
    src:url("../src/lib/assets/WebFonts/Fonts/SlussenBold/Slussen-Bold.woff2") format("woff2"),url("../src/lib/assets/WebFonts/Fonts/SlussenBold/Slussen-Bold.woff") format("woff");
}
@font-face{
    font-family:"Slussen-Extralight";
    src:url("../src/lib/assets/WebFonts/Fonts/SlussenExtralight/Slussen-Extralight.woff2") format("woff2"),url("../src/lib/assets/WebFonts/Fonts/SlussenExtralight/Slussen-Extralight.woff") format("woff");
}
@font-face{
    font-family:"Slussen-Medium";
    src:url("../src/lib/assets/WebFonts/Fonts/SlussenMedium/Slussen-Medium.woff2") format("woff2"),url("../src/lib/assets/WebFonts/Fonts/SlussenMedium/Slussen-Medium.woff") format("woff");
}
@font-face{
    font-family:"Slussen-Light";
    src:url("../src/lib/assets/WebFonts/Fonts/SlussenLight/Slussen-Light.woff2") format("woff2"),url("../src/lib/assets/WebFonts/Fonts/SlussenLight/Slussen-Light.woff") format("woff");
}
@font-face{
    font-family:"Slussen-Semibold";
    src:url("../src/lib/assets/WebFonts/Fonts/SlussenSemibold/Slussen-Semibold.woff2") format("woff2"),url("../src/lib/assets/WebFonts/Fonts/SlussenSemibold/Slussen-Semibold.woff") format("woff");
}
@font-face{
    font-family:"Slussen-Thin";
    src:url("../src/lib/assets/WebFonts/Fonts/SlussenThin/Slussen-Thin.woff2") format("woff2"),url("../src/lib/assets/WebFonts/Fonts/SlussenThin/Slussen-Thin.woff") format("woff");
}
@font-face{
    font-family:"Slussen-Regular";
    src:url("../src/lib/assets/WebFonts/Fonts/SlussenRegular/Slussen-Regular.woff2") format("woff2"),url("../src/lib/assets/WebFonts/Fonts/SlussenRegular/Slussen-Regular.woff") format("woff");
}



body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
